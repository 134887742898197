import React, { useState, useEffect, useRef } from "react";
import CustomSelect from "../../components/Select";
import CustomInput from "../../components/Input";
import OTPInput from "../../components/OTPInput";
import { Button, Row, Col } from "antd";
import "./style.scss";
import { refresh } from "../../variables/image";
import DoctorService from "../../services/doctor.api";
import ProductService from "../../services/product.api";
import _ from "lodash";

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}
window.recaptchaVerifier = window.recaptchaVerifier || {};
window.confirmationResult = window.recaptchaVerifier || {};

type Props = {
  setStage: any;
  setDoctor?: any;
  setChannel?: any;
  setDoctorName?: any;
  setPhoneNum?: any;
  setOtp?: any;
  phoneOtp?: string;
};

const ProductPage = (props: Props) => {
  const typingTimeoutRef: any = useRef();

  const [department, setDepartment] = useState("");

  const [hospital, setHospital] = useState("");

  const [phoneNum, setPhoneNum] = useState("");
  const [otpValues, setOTPValues] = useState(Array(6).fill(""));

  const [otp, setOtp] = useState("");
  const [errOtp, setErrOtp] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [isSent, setSend] = useState(false);
  const [isSentAgain, setIsSentAgain] = useState(false);
  const [doctor, setSelectDoctor] = useState();
  const [doctorListAll, setDoctorListAll] = useState<any[]>([]);
  const [channel, setChannel] = useState();
  const [channelList, setChannelList] = useState([]);

  const getDoctorList = async (search?: string) => {
    DoctorService.getList(search).then((res) => {
      if (res.data) {
        const doctors = res.data.map((prop: any, key: number) => {
          prop.label = prop && prop.profile ? prop.profile.fullName : "";
          prop.value = prop && prop._id ? prop._id : "";
          return prop;
        });
        setDoctorListAll(_.uniqBy([...doctors], "_id"));
        // setDoctorList(res.data.filter((prop: any, key: number) => key < 300));
      }
    })
  };

  // const findDoctorName = () => {
  //   var doctorObj: any = doctorListAll.find((item: any) => item._id === doctor);
  //   props.setDoctorName(doctorObj.label);
  // };

  const onSearchDoctor = (value: string) => {
    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      getDoctorList(value)
    }, 700);
  };

  const handleOnChangeDoctor = (value: any, options: any) => {
    setSelectDoctor(value);
    props.setDoctor(value);
    if (value !== undefined && value !== "") {
      props.setDoctorName(options?.props?.label);

      getChannelList(value);
      let doctorObj: any = doctorListAll.find((item: any) => item._id === value);
      setPhoneNum(doctorObj?.profile?.phone || doctorObj?.profile?.phoneOrder || "");
      if (_.isEmpty(doctorObj)) {

        setDoctorListAll([...doctorListAll, { label: options?.props?.label, value: options?.props?.value }])
      }
    } else {
      setDepartment("");
      setHospital("");
      getDoctorList("")
      setChannel(undefined);
      setChannelList([]);
    }
  };

  const getChannelList = async (id: any) => {
    DoctorService.getChannelByDoctor(id).then((res) => {
      if (res.code === -1) {
        console.log("Có lỗi xảy ra, không lấy được dữ liệu");
      } else {
        const data = res.data.map((prop: any) => {
          prop.label = prop.channel + " " + prop.channelId;
          prop.value = prop._id;
          return prop;
        });
        setChannelList(data);

        if (data[0] !== undefined) {
          setChannel(data[0]._id);
          var doctorObj: any = data.find((item: any) => item._id === data[0]._id);
          setDepartment(doctorObj.department);
          setHospital(doctorObj.office);

          return;
        } else {
          setChannel(undefined);
          setDepartment("");
          setHospital("");
        }
        // return;

      }
    });
  };
  const handleOnChangeChannel = (value: any) => {
    setChannel(value);

    if (value !== undefined && value !== "") {
      var doctorObj: any = channelList.find((item: any) => item._id === value);
      setDepartment(doctorObj.department);
      setHospital(doctorObj.office);
    } else {
      setDepartment("");
      setHospital("");
      setChannel(undefined);
    }
  };

  useEffect(() => {
    getDoctorList();
  }, []);

  const checkOTP = () => {
    let data = {
      phone: phoneNum,
      code: otp,
    };
    if (doctor) {
      props.setDoctor(doctor);
      props.setPhoneNum(phoneNum);
      props.setChannel(channel);
      // findDoctorName();
      props.setStage(2);
    }

    // ProductService.checkOTP(data).then((res) => {
    //   if (res.code === 1 && res.data.isValid) {
    //     props.setDoctor(doctor);
    //     props.setPhoneNum(phoneNum);
    //     props.setChannel(channel);
    //     // findDoctorName();
    //     props.setStage(2);
    //   } else if (res.code === -1 && res.error === "CODE_EXPIRED") {
    //     setErrOtp("Mã xác thực OTP hết hạn. Vui lòng gửi lại!");
    //   } else if (res.code === -1 && res.error === "CODE_IS_INCORRECT") {
    //     setErrOtp("Mã xác thực OTP chưa đúng. Vui lòng nhập lại!");
    //   }
    // });
  };

  const sendOtp = () => {
    setIsSentAgain(!isSentAgain);
    setOtp("");
    setOTPValues(Array<string>(6).fill(""));
    props.setPhoneNum(phoneNum);
    setTimeout(() => {
      setIsSentAgain(false);
    }, 10000);
    const data = {
      phone: phoneNum,
    };
    const regexp = /^[0-9]*$/;
    const checkingResult = regexp.exec(phoneNum);
    if (checkingResult === null) {
      setErrPhone("Số điện thoại không được là chữ ký tự");
    } else {
      setErrPhone("");
      ProductService.sendOTPSurvey(data).then((res) => {
        console.log(res);
      });
    }
  };

  const handleSendOTP = () => {
    setIsSentAgain(!isSentAgain);
    setTimeout(() => {
      setIsSentAgain(false);
    }, 10000);
    setSend(!isSent);
    sendOtp();
  };

  return (
    <div className="product-page-container">
      <div className="product-page-content">
        <div className="product-page">
          <h1>
            <span className="besins-color">BESINS</span> Khảo sát sản phẩm
          </h1>
          <p className="besins-description">
            Nhập các trường thông tin để bắt đầu khảo sát
          </p>
          <div className="product-form">
            <Row>
              <CustomSelect
                value={doctor}
                onChange={handleOnChangeDoctor}
                label="Bác sĩ"
                allowClear
                withIcon={true}
                disabled={false}
                filterOption={false}
                onSearch={onSearchDoctor}
                // optionFilterProp="children"
                placeholder="Chọn bác sĩ"
                showSearch
                option={doctorListAll}
              />
            </Row>
            <Row>
              <CustomSelect
                value={channel}
                onChange={handleOnChangeChannel}
                label="Kênh"
                allowClear
                withIcon={true}
                disabled={doctor === undefined}
                optionFilterProp="label"
                placeholder="Chọn kênh"
                showSearch={false}
                option={channelList}
              />
            </Row>
            <Row style={{ margin: "20px 0" }}>
              <CustomInput
                label={"Khoa"}
                placeholder={"Khoa"}
                size={"large"}
                type={"text"}
                value={department}
                onChange={setDepartment}
                readOnly
              />
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <CustomInput
                label={"Bệnh viện"}
                placeholder={"Bệnh viện"}
                size={"large"}
                type={"text"}
                value={hospital}
                onChange={setHospital}
                readOnly
              />
            </Row>
            <Row gutter={32}>
              <Col md={32} xs={32} style={{ paddingRight: 0 }}>
                <CustomInput
                  label={"Số điện thoại"}
                  placeholder={"Ví dụ: 090xxxxxx"}
                  size={"large"}
                  disabled={true}
                  type={"text"}
                  value={phoneNum}
                  onChange={setPhoneNum}
                  readOnly={channel === undefined}
                />
              </Col>
              {/* <Col md={9} xs={9} style={{ height: 40 }}>
                {!isSent && (
                  <Button
                    size="large"
                    className="btn-otp"
                    style={{ margin: "28px 0" }}
                    block
                    onClick={handleSendOTP}
                    disabled={phoneNum.length < 10}
                  >
                    <span className="send-otp">Gửi OTP</span>
                  </Button>
                )}
                {isSent && (
                  <Button
                    size="large"
                    className="btn-otp"
                    style={{ margin: "28px 0" }}
                    block
                    onClick={sendOtp}
                    disabled={phoneNum.length < 10 || isSentAgain}
                  >
                    <span className="sent-otp">
                      <img
                        src={refresh}
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      Gửi lại
                    </span>
                  </Button>
                )}
              </Col> */}
            </Row>
            {/* <span className="phone-desc">
              Một mã OTP sẽ được gửi đến số điện thoại của bạn.
            </span>
            {errPhone && <p style={{ color: "#F45B19" }}>{errPhone}</p>} */}
            <div id="recaptcha-container"></div>
            {/* <Row style={{ marginTop: 28 }}>
              <OTPInput
                label="Nhập OTP"
                length={6}
                className="otpContainer"
                inputClassName="otpInput"
                isNumberInput
                autoFocus
                error={errOtp !== ""}
                errorText={errOtp}
                value={otp}
                otpValues={otpValues}
                setOTPValues={setOTPValues}
                onChangeOTP={setOtp}
                disabled={!isSent}
              />
            </Row> */}
            <Row style={{ marginTop: 20 }}>
              <Button
                // disabled={otp.length < 6 || phoneNum !== props.phoneOtp}
                disabled={!doctor}
                onClick={() => checkOTP()}
                type="primary"
                block
              >
                Tiếp tục
              </Button>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
